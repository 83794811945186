<script>
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";
</script>

<style  global>@font-face {
    font-family: 'GameFont';
    src: url('../Lalezar-Regular.ttf') format('truetype'); /* IE9 Compat Modes */
}

:global(html), :global(body) {
    position: relative;
    width: 100%;
    height: 100%;

    --bg: #f6f6f6;
}

:global(body) {
    margin: 0;
    padding: 0px;
    box-sizing: border-box;
    font-family: "GameFont", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif !important;
}

:global(#routify-app) {
    width: 100%;
    height: 100%;
    padding: 0px;
    background: var(--bg);
}

:global(label) {
    display: block;
}

:global(input), :global(button), :global(select), :global(textarea) {
    font-family: inherit;
    font-size: inherit;
    padding: 0.4em;
    margin: 0 0 0.5em 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 2px;
}

:global(input:disabled) {
    color: #ccc;
}

:global(input[type="range"]) {
    height: 0;
}

:global(button) {
    color: #333;
    background-color: #f4f4f4;
    outline: none;
}

:global(button:disabled) {
    color: #999;
}

:global(button:not(:disabled):active) {
    background-color: #ddd;
}

:global(button:focus) {
    border-color: #666;
}

:global(a:hover) {
    color: inherit !important;
}

:global(.btn-big) {
    font-size: 4rem !important;
    appearance: button;
    text-align: center;
    text-decoration: none !important;
    padding: 0.5rem;
}

:global(.btn-small) {
    font-size: 1rem !important;
    appearance: button;
    text-align: center;
    text-decoration: none !important;
    border: none;
    padding: 0.5rem;
}

:global(.primary) {
    background: #68b0ab;
    color: #1a1c20;
}

:global(.secondary) {
    background: #709fb0;
    color: #1a1c20;
}

:global(.danger) {
    background: #ff7e67;
    color: #1a1c20;
}

:global(table) {
    font-family: Arial, Helvetica, sans-serif;
}</style>

<Router {routes} />